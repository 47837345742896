import axios from "axios"


axios.defaults.baseURL = process.env.REACT_APP_API;


export const getNoticeList = (type:string,festivalId:number) => {
    return axios.get(`/notice/${festivalId}`,{params:{type:type}});
}

export const getNoticeDetail = (festivalId:number, noticeId:string)=>{
    return axios.get(`/notice/${festivalId}/${noticeId}`);
}