import NoticePostHeader from 'components/Notice/NoticePostHeader';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import back from 'assets/inquiry/back.svg';
import { useEffect, useState } from 'react';

import sampleNotice from 'assets/notice/SampleNotice.png';
import { getNoticeDetail } from 'apis/noticeApis';
// import getImageSize from 'utils/getImageSize';
// import SizedIframe from 'components/SizedIframe';

interface INoticeDetail {
  images: Array<string>;
  title: string;
  subtitle: string;
  createdDate: string;
  content?: string;
}

function NoticePost() {
  const navigate = useNavigate();
  const noticeId = useParams().id;
  const festivalId = 1;

  const [imgWidth, setImgWidth] = useState(0);
  const [imgHeight, setImgHeight] = useState(0);
  const [noticeDetail, setNoticeDetail] = useState<INoticeDetail>({
    images: [],
    title: '2024 목포해상 W쇼 행사 안내',
    subtitle: '정기공연 / 특별공연 안내',
    createdDate: '2024. 02. 12 16:00',
    content: `안녕하세요,
2024년 목포해상 W쇼 행사 안내드립니다.

<정기공연>은 총 3회 진행 예정이며,

총 3일간,
일자 : 4월 27일(토) / 5월 25일(토) / 9월 14일(토)
시간 : 저녁 8시-9시
장소 : 평화광장 해상무대(목포시 평화로 82) 에서 진행됩니다.

<특별공연>은 1회 진행 예정이며,

일자 : 7월 27일(토)
시간 : 저녁 8시-9시
장소 : 북항노을공원 일원(목포시 청호로219번길 34-22) 에서 진행됩니다.

자세한 내용은 세부일정 홈페이지를 참조해주세요.

목포 시민 여러분과 방문객들께서 많은 관심과 방문 부탁드립니다.

감사합니다.`,
    // images:[],
  });

  // useEffect(()=>{

  //   getImageSize(noticeDetail.thumbnail)
  //   .then((response:any)=>{
  //       setImgWidth(response.width);
  //       setImgHeight(response.height);
  //       console.log('width,height', response.width,response.height)
  //     })
  //   .catch(error=>console.log(error));

  // },[noticeDetail]);

  // console.log(noticeDetail.content?.split('\n'))

  useEffect(() => {
    window.scrollTo(0, 0);
    if (noticeId !== undefined) {
      getNoticeDetail(festivalId, noticeId)
        .then((response: any) => {
          //console.log(response.data);
          setNoticeDetail({
            ...response.data,
            time:
              response.data.time?.slice(0, 10) +
              ' ' +
              response.data.time?.slice(11, 19),
          });
        })
        .catch((error: any) => {
          alert(error);
        });
    }
  }, []);

  return (
    <NoticePostContainer>
      <Back
        src={back}
        alt=""
        onClick={() => {
          navigate(-1);
        }}
      />

      <NoticePostHeader
        title={noticeDetail.title}
        createdDate={noticeDetail.createdDate}
      />

      <ContentBox>
        {noticeDetail.images.map((item: string, index: number) => (
          <img key={index} src={item} />
        ))}

        <h1>{noticeDetail.subtitle}</h1>
        {noticeDetail.content
          ?.split('\n')
          .map((item: string, index: number) => {
            if (item === '') return <br key={index} />;
            else return <h2 key={index}>{item}</h2>;
          })}
      </ContentBox>
    </NoticePostContainer>
  );
}

export default NoticePost;

const NoticePostContainer = styled.div`
  width: 820px;
  margin: 40px auto;

  display: flex;
  flex-direction: column;
  @media screen and (max-width: 420px) {
    width: 100%;
    margin-top: 20px;
  }
`;
const ContentBox = styled.div`
  align-self: center;

  width: 100%;
  background: #111;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 610px;
    height: 610px;

    margin-bottom: 32px;
  }

  h1 {
    width: 610px;
    color: #fff;
    margin-bottom: 16px;

    font-size: 24px;

    font-weight: 600;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.375px;
    pointer-events: none;
  }

  h2 {
    width: 610px;
    color: #fff;

    font-size: 18px;

    font-weight: 200;
    line-height: 27px;
    letter-spacing: -0.375px;
    pointer-events: none;
  }
  @media screen and (max-width: 420px) {
    img {
      width: 90vw;
      height: 90vw;
    }
    h2 {
      width: 90vw;
    }
    h1 {
      width: 90vw;
    }
  }
`;
export const Back = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;
