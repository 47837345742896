import { Navigate, createBrowserRouter } from 'react-router-dom';
import App from './App';

import Inquiry from 'pages/Inquiry';
import InquiryWrite from 'pages/InquiryWrite';
import InquiryPost from 'pages/InquiryPost';
import Notice from './pages/Notice';
import NoticePost from 'pages/NoticePost';
import Landing from 'pages/Landing';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '',
        element: <Landing />,
        children: [],
      },
      {
        path: '/inquiry',
        element: <Inquiry />,
        children: [],
      },
      {
        path: '/inquiry/:id',
        element: <InquiryPost />,
        children: [],
      },
      {
        path: '/inquiry/write',
        element: <InquiryWrite />,
        children: [],
      },

      {
        path: `/notice`,
        element: <Notice />,
      },
      {
        path: `/notice/:id`,
        element: <NoticePost />,
      },
    ],
  },
  { path: '*', element: <Navigate replace to="/" /> },
]);

export default router;

//router함수가 App.js의 컴포넌트 역할 (Router.tsx는 App.js의 역할)
//해당 router함수는 index에서 import됨
