import { Helmet } from 'react-helmet-async';

const MetaTag = () => {
  // props로 content 내용을 불러올 예정임
  return (
    <Helmet>
      <title>목포해상 W쇼</title>
      <meta
        name="description"
        content="해상무대에서 펼쳐지는 환상적인 공연과 불꽃의 만남, 국내 유일 해상 오브제 불꽃쇼 목포해상W쇼."
        data-react-helmet="true"
      />
      <meta property="og:url" content="https://mokpowshow.co.kr" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="목포해상 W쇼" />
      <meta
        property="og:description"
        content="해상무대에서 펼쳐지는 환상적인 공연과 불꽃의 만남, 국내 유일 해상 오브제 불꽃쇼 목포해상W쇼."
      />

      {/* <meta property="og:image" content="redkiwi.jpg" /> */}
    </Helmet>
  );
};

export default MetaTag;
