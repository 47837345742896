import { getInquiryPost } from 'apis/inquiryApis';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FlexBox } from 'styles/GlobalStyle';

function PasswordPopup({
  setPasswordPopup,
  setPassword,
  id,
  userId,
  password,
}: {
  setPasswordPopup: any;
  setPassword: any;
  id: number;
  userId: string;
  password: string;
}) {
  const navigate = useNavigate();
  const handleCancle = () => {
    setPasswordPopup(false);
  };
  const handlePassword = (e: any) => {
    setPassword(e.target.value);
  };
  const handleOk = () => {
    getInquiryPost({ id, userId, password })
      .then((res) => {
        navigate(`${id}`, { state: { props: res.data } });
      })
      .catch((err) => {
        alert('비밀번호가 올바르지 않습니다.');
      });
  };
  return (
    <PopupContainer>
      <PopupBox>
        <h1>비밀번호 입력</h1>
        <input onChange={handlePassword} placeholder="비밀번호 4자리 입력" />
        <BtnBox>
          <CancleBtn onClick={handleCancle}>취소</CancleBtn>
          <OkBtn onClick={handleOk}>확인</OkBtn>
        </BtnBox>
      </PopupBox>
    </PopupContainer>
  );
}
export default PasswordPopup;

const PopupContainer = styled.div`
  position: fixed; // 위치를 fixed로 변경하여 스크롤에 영향을 받지 않도록 합니다.
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7); // 배경을 반투명 검은색으로 설정
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PopupBox = styled.div`
  width: 296px;
  height: 192px;
  background-color: #fff;
  border-radius: 8px;

  padding: 24px; // 내부 여백 추가

  h1 {
    color: #111;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: -0.4px;
  }
  input {
    width: 248px;
    height: 44px;
    flex-shrink: 0;
    border-bottom: 1px solid var(--Line-Regular_Color, #e5e5ec);
    background: #fff;
    margin-top: 8px;
  }
`;

const OkBtn = styled.button`
  width: 120px;
  height: 44px;
  flex-shrink: 0;
  background: var(--Font-02_black, #111);
  color: #fff;

  font-size: 14px;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.35px;
`;
const CancleBtn = styled(OkBtn)`
  color: #111;
  border: 1px solid var(--Line-Regular_Color, #e5e5ec);
  background: var(--Font-01_White, #fff);
`;

const BtnBox = styled(FlexBox)`
  margin-top: 24px;
  gap: 8px;
`;
