import styled from 'styled-components';

function Footer() {
  return (
    <FooterContainer>
      <TextBox>
        <p>58613) 전라남도 목포시 양을로 203 (용당동) 목포시청</p>
        <p>대표전화 061-272-2171 / 대표팩스 061-270-3598</p>
        <p>Copyright ⓒ MOKPO-CITY. All Rights Reserved</p>
      </TextBox>
    </FooterContainer>
  );
}
export default Footer;

const FooterContainer = styled.div`
  position: relative;
  /* width: 1280px; */
  margin: 120px 0 0;
  border-top: 1px solid #555;
  background: #111;

  height: 240px;

  p {
    margin-top: 4px;
    color: #999;
    text-align: center;
    font-size: 14px;
    font-weight: 400;

    line-height: 20px; /* 142.857% */
    letter-spacing: -0.35px;
  }
`;
const TextBox = styled.div`
  margin: 32px auto;
`;
