import WriteHeader from 'components/Inquiry/InquiryWrite/WriteHeader';
import WriteInput from 'components/Inquiry/InquiryWrite/WriteInput';
import styled from 'styled-components';

function InquiryWrite() {
  return (
    <Container>
      <WriteHeader />
      <Line />
      <WriteInput />
    </Container>
  );
}

export default InquiryWrite;

const Container = styled.div`
  width: 400px;
  margin: 0 auto;
`;
const Line = styled.div`
  margin: 0 auto;
  background: #999;
  width: 400px;
  height: 1px;
  flex-shrink: 0;
`;
