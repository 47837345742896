import axios from 'axios';
import { IinquiryInfo } from 'recoil/atom';
axios.defaults.baseURL = process.env.REACT_APP_API;

export const postInquiry = (info: IinquiryInfo) => {
  return axios.post('/inquiry', info);
};

export const getInquiryBoard = () => {
  return axios.get('/inquiry');
};

export interface IgetInquiryPost {
  id: number;
  userId: string;
  password: string | null;
}
export const getInquiryPost = ({ id, userId, password }: IgetInquiryPost) => {
  return axios.post(`/inquiry/${id}`, { userId: userId, password: password });
};
