import { inquiryPostData } from 'assets/inquiry/inquiryPostData';
import InquiryPostHeader from 'components/Inquiry/InquiryPost/InquiryPostHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import back from 'assets/inquiry/back.svg';
import InquiryAnswer from 'components/Inquiry/InquiryPost/InquiryAnswer';
import { useEffect } from 'react';
interface IinquiryPost {
  answer: string;
  content: string;
  createdDate: string;
  isAnswered: Boolean;
  title: string;
  userId: string;
}

function InquiryPost() {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  const location: IinquiryPost = useLocation().state.props;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <InquiryPostContainer>
      <Back src={back} alt="" onClick={handleBack} />
      <InquiryPostHeader info={location} />
      <ContentBox readOnly>{location.content}</ContentBox>
      <InquiryAnswer answer={location.answer} />
    </InquiryPostContainer>
  );
}
export default InquiryPost;

const InquiryPostContainer = styled.div`
  width: 610px;
  margin: 40px auto;
  @media screen and (max-width: 420px) {
    width: 100%;
  }
`;
const ContentBox = styled.textarea`
  @media screen and (max-width: 420px) {
    width: 100%;
  }
  padding: 16px;
  width: 610px;
  height: 212px;
  background: #111;
  color: #fff;

  font-size: 15px;

  font-weight: 400;
  line-height: 22px; /* 146.667% */
  letter-spacing: -0.375px;
  pointer-events: none;
`;
export const Back = styled.img`
  cursor: pointer;
`;
