import styled from 'styled-components';
import back from 'assets/inquiry/back.svg';
import { FlexBox } from 'styles/GlobalStyle';
import { useNavigate } from 'react-router-dom';
import { Back } from 'pages/InquiryPost';
function WriteHeader() {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <WriteHeaderContainer>
      <Back src={back} alt="" onClick={handleBack} />
      문의사항 작성
    </WriteHeaderContainer>
  );
}
export default WriteHeader;
const WriteHeaderContainer = styled(FlexBox)`
  align-items: center;
  padding: 20px 0;

  margin: 20px 0 0 0;
  gap: 12px;

  font-size: 24px;
  font-weight: 600;
  line-height: 34px; /* 141.667% */
  letter-spacing: -0.6px;
`;
