import { notAnswered } from 'assets/inquiry/inquiryAnswer';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';

function InquiryAnswer({ answer }: { answer: string }) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto'; // 높이를 초기화
      textarea.style.height = textarea.scrollHeight + 'px'; // 새로운 내용에 맞게 높이 조절
    }
  }, []);
  return (
    <AnswerContainer>
      {answer ? <h1>답변</h1> : ''}
      <textarea
        ref={textareaRef}
        readOnly
        value={answer ? answer : notAnswered}
      />
    </AnswerContainer>
  );
}
export default InquiryAnswer;

const AnswerContainer = styled.div`
  width: 610px;

  flex-shrink: 0;
  border-radius: 8px;
  background: #1a1a1a;
  margin-top: 20px;
  padding: 16px;
  h1 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: -0.4px;
  }
  textarea {
    margin-top: 12px;
    position: relative;
    height: 100%;
    overflow: hidden;
    background: #1a1a1a;
    width: 578px;

    pointer-events: none;

    font-size: 15px;
    font-weight: 400;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.375px;
  }
  @media screen and (max-width: 420px) {
    width: 100%;
    textarea {
      width: 100%;
    }
  }
`;
