import styled from 'styled-components';
import { FlexBox } from 'styles/GlobalStyle';

function NoticePostHeader({
  title,
  createdDate,
}: {
  title: string;
  createdDate: string;
}) {
  return (
    <HeaderContainer>
      <h1>{title}</h1>
      <SubBox>
        <h2 style={{ color: '#999' }}>
          {createdDate === 'undefined undefined' ? '' : createdDate}
        </h2>
      </SubBox>
    </HeaderContainer>
  );
}
export default NoticePostHeader;

const HeaderContainer = styled.div`
  height: 90px;
  margin-bottom: 16px;

  flex-shrink: 0;

  border-bottom: 1px solid #999;

  display: flex;
  flex-direction: column;

  h1 {
    margin-top: 16px;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px; /* 140% */
    letter-spacing: -0.5px;
  }
  h2 {
    font-size: 15px;
    font-weight: 400;
    line-height: 22px; /* 146.667% */
  }
  @media screen and (max-width: 420px) {
    padding-left: 20px;
    width: 100vw;
  }
`;
const SubBox = styled(FlexBox)`
  margin-top: 8px;
  gap: 8px;
`;
