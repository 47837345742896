import styled from 'styled-components';
import { FlexBox } from 'styles/GlobalStyle';
import check from 'assets/inquiry/check.svg';
import checked from 'assets/inquiry/checked.svg';
import { useState } from 'react';
import { inquiryInfoState } from 'recoil/atom';
import { useRecoilState } from 'recoil';
import { postInquiry } from 'apis/inquiryApis';
import { useNavigate } from 'react-router-dom';

function WriteInput() {
  const [onSecret, setOnSecret] = useState(false);
  const [inquiryInfo, setInquiryInfo] = useRecoilState(inquiryInfoState);
  const navigate = useNavigate();
  const handleInfo = (e: any) => {
    setInquiryInfo({ ...inquiryInfo, [e.target.name]: e.target.value });
  };
  const handleSecret = () => {
    setOnSecret(!onSecret);
    setInquiryInfo({ ...inquiryInfo, ['isSecret']: !onSecret });
  };

  const handleSubmit = () => {
    postInquiry(inquiryInfo)
      .then((res) => {
        alert('성공적으로 등록되었습니다.');
        navigate('/inquiry');
      })
      .catch((err) => {
        alert('오류가 발생했습니다. 새로고침 후 다시 시도해주세요');
      });
  };
  return (
    <WriteInputContainer>
      <Title style={{ marginTop: '24px' }}>제목</Title>
      <FlexBox style={{ alignItems: 'center' }}>
        <Input name="title" onChange={handleInfo} placeholder="제목 입력" />
        <CheckBox onSecret={onSecret} onClick={handleSecret}>
          {onSecret ? <img src={checked} alt="" /> : <img src={check} alt="" />}
        </CheckBox>
        <span style={{ marginLeft: '8px' }}>비밀글</span>
      </FlexBox>
      <FlexBox>
        <div>
          <InputTitleBox>
            <Title>이름</Title>
          </InputTitleBox>
          <SmallInput
            name="userId"
            onChange={handleInfo}
            placeholder="이름 입력"
          />
        </div>
        {onSecret && (
          <div style={{ marginLeft: '20px' }}>
            <InputTitleBox>
              <Title>비밀번호</Title>
              <p>숫자 4자리</p>
            </InputTitleBox>
            <SmallInput
              name="password"
              onChange={handleInfo}
              placeholder="비밀번호 입력"
            />
          </div>
        )}
      </FlexBox>
      <Title style={{ marginTop: '24px' }}>내용</Title>
      <Content name="content" onChange={handleInfo} placeholder="내용 입력" />
      <SubmitBtn onClick={handleSubmit}>제출</SubmitBtn>
    </WriteInputContainer>
  );
}
export default WriteInput;

const WriteInputContainer = styled.div`
  width: 400px;
  margin: 0 auto;
  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.35px;
  }
  p {
    color: #53cddd;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px; /* 150% */
    letter-spacing: -0.3px;
  }
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.35px;
`;
const Input = styled.input`
  margin-top: 8px;
  width: 320px;
  height: 44px;
  flex-shrink: 0;
  border-bottom: 1px solid #555;
  background: #111;
  color: #fff;
`;
const SmallInput = styled(Input)`
  width: 190px;
`;
const InputTitleBox = styled(FlexBox)`
  margin-top: 24px;
  gap: 4px;
  align-items: center;
`;

const CheckBox = styled(FlexBox)<{ onSecret: boolean }>`
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  flex-shrink: 0;

  border-radius: 11px;
  border: 1px solid #999;
  background: #111;

  margin-left: 16px;
  background-color: ${(props) => (props.onSecret ? '#FFF' : '#111')};

  cursor: pointer;
`;

const Content = styled.textarea`
  border: 1px solid #555;
  margin-top: 8px;
  background: #111;

  width: 400px;
  height: 232px;
  flex-shrink: 0;
  padding: 16px;

  font-size: 14px;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.35px;

  color: #fff;
`;

const SubmitBtn = styled.button`
  margin-top: 60px;
  width: 400px;
  height: 44px;
  flex-shrink: 0;
  background: #53cddd;
  color: #111;
  text-align: center;

  font-size: 14px;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.35px;
`;
