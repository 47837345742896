import InquiryPostPreview from './InquiryBoard/InquiryPostPreview';
import InquiryBoardHeader from './InquiryBoard/InquiryBoardHeader';
import { getInquiryBoard } from 'apis/inquiryApis';
import { useEffect, useState } from 'react';
export interface IinquiryInfo {
  id: number;
  title: string;
  userId: string;
  createdDate: string;
  isAnswered: boolean;
  isSecret: boolean;
}
function InquiryBoard() {
  const [inquiryBoard, setInquiryBoard] = useState<IinquiryInfo[]>();

  useEffect(() => {
    getInquiryBoard()
      .then((res) => {
        setInquiryBoard(res.data);
      })
      .catch((err) => {});
  }, []);
  function sortDate(list: IinquiryInfo[]) {
    const sorted_list = list.sort(function (a: IinquiryInfo, b: IinquiryInfo) {
      return (
        new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
      );
    });
    return sorted_list;
  }

  return (
    <>
      <InquiryBoardHeader />

      {inquiryBoard &&
        sortDate(inquiryBoard).map((info) => {
          return <InquiryPostPreview {...info} />;
        })}
    </>
  );
}
export default InquiryBoard;
