import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FlexBox } from 'styles/GlobalStyle';

function InquiryHeader() {
  const navigate = useNavigate();
  const handleWriteBtn = () => {
    navigate('/inquiry/write');
  };
  return (
    <InquiryHeaderContainer>
      <h1>문의사항</h1>
      <button onClick={handleWriteBtn}>문의사항 작성하기</button>
    </InquiryHeaderContainer>
  );
}
export default InquiryHeader;

const InquiryHeaderContainer = styled(FlexBox)`
  width: 820px;
  margin: 64px auto 24px;
  @media screen and (max-width: 420px) {
    width: 100%;
    padding: 0 20px;
    margin: 12px auto 24px;
  }
  h1 {
    font-size: 24px;
    font-weight: 600;
    line-height: 34px; /* 141.667% */
    letter-spacing: -0.6px;
  }
  button {
    padding: 3px 8px;

    gap: 10px;
    border-radius: 4px;
    background: #53cddd;
    color: #111;

    font-size: 12px;
    font-weight: 600;
    line-height: 18px; /* 150% */
    letter-spacing: -0.3px;
  }
  justify-content: space-between;
`;
