import { Outlet } from 'react-router-dom';
import Header from './components/layouts/Header';
import { GlobalStyle } from './styles/GlobalStyle';
import Footer from 'components/layouts/Footer';
import styled from 'styled-components';
import { RecoilRoot } from 'recoil';
import MetaTag from 'assets/\bseo/SEOMetaTag';

function App() {
  return (
    <>
      <MetaTag />
      <RecoilRoot>
        <GlobalStyle />
        <Wrapper>
          <Header />
          <Outlet />
        </Wrapper>
        <Footer />
      </RecoilRoot>
    </>
  );
}

export default App;

const Wrapper = styled.div`
  min-height: calc(100vh - 260px); /* 푸터 높이를 제외한 나머지 높이 */
`;
