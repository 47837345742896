import { atom } from 'recoil';
export interface IinquiryInfo {
  title: string;
  userId: string;
  password: string;
  isSecret: boolean;
  content: string;
}
export const inquiryInfoState = atom<IinquiryInfo>({
  key: 'inquiryInfoState',
  default: {
    title: '',
    userId: '',
    password: '',
    isSecret: false,
    content: '',
  },
});

export const mobilePopupState = atom({
  key: 'mobilePopup',
  default: true,
});
