import styled, { createGlobalStyle } from 'styled-components';
export const GlobalStyle = createGlobalStyle`

*, *::before, *::after { // css 모든 요소 마진, 패딩 제거ㄴ
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: pretendard Variable;
  }
  html,body{
    height: 100%;
  }
body{
    background-color: #111;
    font-family: pretendard Variable;
    color : #fff;
}
button{

  justify-content: center;
    align-items: center;
    border: 0;
    cursor: pointer;
}
input{
  border: 0;
  outline:none;
}
textarea{
  resize: none; 
  outline:none;
  border:none;
  background: #111;
  color : #fff;
}
`;

export const FlexBox = styled.div`
  display: flex;
`;
