import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import sampleNotice from 'assets/notice/SampleNotice.png';
import { useEffect, useState } from 'react';
import { getNoticeList } from 'apis/noticeApis';
import NoticeMetatag from 'assets/\bseo/NoticeMetaTag';

interface INotice {
  id: number;
  thumbnail: string;
  title: string;
  time: string;
}

function Notice() {
  const navigate = useNavigate();
  const festivalId = 1;

  const [noticeList, setNoticeList] = useState<INotice[]>();

  useEffect(() => {
    getNoticeList('NOTICE', festivalId)
      .then((response: any) => {
        setNoticeList(
          response.data.sort((a: INotice, b: INotice) => b.id - a.id)
        );
        //console.log(response.data.sort((a:INotice,b:INotice)=>b.id-a.id));
      })
      .catch((error: any) => alert(error));
  }, []);

  return (
    <>
      <NoticeMetatag />
      <NoticeContainer>
        {noticeList?.map((item: INotice, index: number) => (
          <NoticeElement key={item.time} onClick={() => navigate(`${item.id}`)}>
            <img src={item.thumbnail} alt="" />
            <h1>{item.title}</h1>
            <h2>{item.time.slice(0, 10) + ' ' + item.time.slice(11, 19)}</h2>
          </NoticeElement>
        ))}
      </NoticeContainer>
    </>
  );
}

export default Notice;

const NoticeContainer = styled.div`
  width: 1240px;

  margin: 40px auto;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 364px;
  //셀마다의 높이는 지정해주면서 요소 개수가 열 수를 넘어가면 알아서 줄바꿈 되도록 하는 속성
  gap: 40px 20px;
  @media screen and (max-width: 420px) {
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
  }
`;

const NoticeElement = styled.div`
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  @media screen and (max-width: 420px) {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  img {
    width: 295px;
    height: 295px;
    margin-bottom: 12px;
    border-radius: 8px;
  }

  h1 {
    margin-bottom: 8px;

    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.45px;
  }

  h2 {
    color: #999;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 146.667% */
  }
`;
