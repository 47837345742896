import styled from 'styled-components';
import { InfoBox, PostBox, TimeBox } from './InquiryPostPreview';

function InquiryBoardHeader() {
  return (
    <HeaderBox>
      <InfoBox>
        <p>답변 여부</p>
        <p>제목</p>
      </InfoBox>
      <InfoBox>
        <p>작성자</p>
        <TimeBox>
          <p>작성시간</p>
        </TimeBox>
      </InfoBox>
    </HeaderBox>
  );
}
export default InquiryBoardHeader;

const HeaderBox = styled(PostBox)`
  border-bottom: 1px solid #999;
  p {
    font-size: 16px;
    color: #fff;
  }
  @media screen and (max-width: 420px) {
    display: none;
  }
`;
