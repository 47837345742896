import styled from 'styled-components';
import { FlexBox } from 'styles/GlobalStyle';
import PasswordPopup from '../PasswordPopup';
import { useState } from 'react';
import locked from 'assets/inquiry/lock.svg';
import { useNavigate } from 'react-router-dom';
import { IinquiryInfo } from '../InquiryBoard';
import { getInquiryPost } from 'apis/inquiryApis';

function InquiryPostPreview({
  id,
  title,
  userId,
  createdDate,
  isAnswered,
  isSecret,
}: IinquiryInfo) {
  const [passwordPopup, setPasswordPopup] = useState(false);
  const [password, setPassword] = useState<string>('');
  let processedId = userId.substring(0, userId.length / 2);
  const navigate = useNavigate();

  for (let i = 0; i < userId.length / 2; i++) {
    processedId = processedId.concat('*');
  }

  const handlePost = () => {
    if (isSecret) {
      setPasswordPopup(true);
    } else {
      getInquiryPost({ id, userId, password })
        .then((res) => {
          navigate(`${id}`, { state: { props: res.data } });
        })
        .catch((err) => {
          alert('오류가 발생했습니다. 새로고침 후 다시 시도해주세요');
        });
    }
  };
  return (
    <PostBox>
      {passwordPopup && (
        <PasswordPopup
          setPassword={setPassword}
          setPasswordPopup={setPasswordPopup}
          id={id}
          userId={userId}
          password={password}
        />
      )}

      <InfoBox>
        <IsAnswerBox isAnswer={isAnswered}>
          <p>{isAnswered ? '답변 완료' : '미답변'}</p>
        </IsAnswerBox>
        <TitleBox onClick={handlePost}>
          {isSecret ? (
            <p>
              비밀글입니다. <img src={locked}></img>
            </p>
          ) : (
            <p>{title}</p>
          )}
        </TitleBox>
        <MobileP>
          {userId ? (
            isSecret ? (
              <p>{processedId}</p>
            ) : (
              <p>{userId}</p>
            )
          ) : (
            <p>익명</p>
          )}
        </MobileP>
      </InfoBox>
      <InfoBox>
        {userId ? (
          isSecret ? (
            <h2>{processedId}</h2>
          ) : (
            <h2>{userId}</h2>
          )
        ) : (
          <h2>익명</h2>
        )}
        <TimeBox>
          <p>
            {createdDate.split('T')[0].split('-').join('.')}{' '}
            {createdDate.split('T')[1].substring(0, 5)}
          </p>
        </TimeBox>
      </InfoBox>
    </PostBox>
  );
}
export default InquiryPostPreview;

const MobileP = styled.div`
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media screen and (min-width: 420px) {
    display: none;
  }
`;
export const PostBox = styled(FlexBox)`
  padding: 14px 20px;

  width: 820px;
  height: 52px;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid #555;

  background: #111;
  p {
    font-size: 15px;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.4px;
  }
  @media screen and (max-width: 420px) {
    width: 100%;
    height: 100px;
  }
`;
export const IsAnswerBox = styled(FlexBox)<{ isAnswer: boolean }>`
  width: 58px;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  color: #999;
  color: ${(props) => props.isAnswer && '#53CDDD;'};
`;
export const InfoBox = styled(FlexBox)`
  gap: 40px;
  h2 {
    color: #fff;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  @media screen and (max-width: 420px) {
    flex-direction: column;
    gap: 0;
    h2 {
      display: none;
    }
  }
`;

export const TimeBox = styled.div`
  width: 132px;
  white-space: nowrap;
  text-align: center;

  color: #999;
  @media screen and (max-width: 420px) {
  }
`;

const TitleBox = styled(FlexBox)`
  cursor: pointer;
  gap: 4px;
  p {
    width: 240px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
