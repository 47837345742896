import InquiryBoard from 'components/Inquiry/InquiryBoard';
import InquiryHeader from 'components/Inquiry/InquiryHeader';
import styled from 'styled-components';

function Inquiry() {
  return (
    <>
      <InquiryContainer>
        <InquiryHeader />
        <InquiryBoard />
      </InquiryContainer>
    </>
  );
}
export default Inquiry;

const InquiryContainer = styled.div`
  width: 820px;
  margin: 64px auto 24px;
  @media screen and (max-width: 420px) {
    width: 100%;
    margin: 0 auto 24px;
  }
`;
