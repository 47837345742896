import styled from 'styled-components';
import { FlexBox } from 'styles/GlobalStyle';

function InquiryPostHeader({ info }: any) {
  return (
    <HeaderContainer>
      <h1>{info.title}</h1>
      <SubBox>
        <h2> {info.userId}</h2>
        <h2 style={{ color: '#999' }}>
          {info.createdDate.split('T')[0].split('-').join('.')}{' '}
          {info.createdDate.split('T')[1].substring(0, 5)}
        </h2>
      </SubBox>
    </HeaderContainer>
  );
}
export default InquiryPostHeader;

const HeaderContainer = styled.div`
  height: 90px;
  border-bottom: 1px solid #999;
  flex-shrink: 0;
  h1 {
    margin-top: 16px;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px; /* 140% */
    letter-spacing: -0.5px;
  }
  h2 {
    font-size: 15px;
    font-weight: 400;
    line-height: 22px; /* 146.667% */
  }
  @media screen and (max-width: 420px) {
    padding-left: 12px;
  }
`;
const SubBox = styled(FlexBox)`
  margin-top: 8px;
  gap: 8px;
`;
